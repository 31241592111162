var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Examination category'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewCheckGroup = true}}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(_vm._s(_vm.$t('add_examination_category'))+" ")],1),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","fab":"","icon":"","outlined":""},on:{"click":function($event){_vm.isAddNewCheckGroup = true}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"outlined":"","dense":""},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.statusList,"label":_vm.$t('status'),"item-text":_vm.$i18n.locale,"outlined":"","dense":"","item-value":"value"},model:{value:(_vm.checkgroup_status_id),callback:function ($$v) {_vm.checkgroup_status_id=$$v},expression:"checkgroup_status_id"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.checkgroup_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.checkgroup_status_id",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":item.checkgroup_status_id}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit = item;_vm.isEditCheckGroup = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":item.checkgroup_active_id == 2},on:{"click":function($event){_vm.checkgroup_id = item.checkgroup_id;_vm.checkgroup_status_id_update = item.checkgroup_status_id;_vm.isUpdateStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.checkgroup_status_id==1? _vm.icons.mdiDeleteOutline:_vm.icons.mdiCheck))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.checkgroup_status_id==1? _vm.$t("suspend") : _vm.$t("normal"))+" ")])]}}],null,true)})],1),_c('AddNewCheckGroup',{on:{"onAdd":_vm.fetchDataTable},model:{value:(_vm.isAddNewCheckGroup),callback:function ($$v) {_vm.isAddNewCheckGroup=$$v},expression:"isAddNewCheckGroup"}}),_c('EditCheckGroup',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":_vm.fetchDataTable},model:{value:(_vm.isEditCheckGroup),callback:function ($$v) {_vm.isEditCheckGroup=$$v},expression:"isEditCheckGroup"}}),_c('UpdateStatusCheckGroup',{attrs:{"checkgroup-id":_vm.checkgroup_id,"checkgroup-status-id":_vm.checkgroup_status_id_update},on:{"onUpdate":_vm.fetchDataTable},model:{value:(_vm.isUpdateStatus),callback:function ($$v) {_vm.isUpdateStatus=$$v},expression:"isUpdateStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }