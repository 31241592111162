<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Examination category') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewCheckGroup = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>{{ $t('add_examination_category') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewCheckGroup = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          class="py-0"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model="checkgroup_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.checkgroup_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.checkgroup_status_id`]="{ item }">
          <StatusBlock :status="item.checkgroup_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditCheckGroup = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t("edit") }}
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.checkgroup_active_id == 2"
                v-bind="attrs"
                @click="checkgroup_id = item.checkgroup_id;checkgroup_status_id_update = item.checkgroup_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.checkgroup_status_id==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            {{ item.checkgroup_status_id==1? $t("suspend") : $t("normal") }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewCheckGroup
      v-model="isAddNewCheckGroup"
      @onAdd="fetchDataTable"
    />
    <EditCheckGroup
      v-model="isEditCheckGroup"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <UpdateStatusCheckGroup
      v-model="isUpdateStatus"
      :checkgroup-id="checkgroup_id"
      :checkgroup-status-id="checkgroup_status_id_update"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck,
} from '@mdi/js'
import useCheckGroup from './useCheckGroup'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewCheckGroup from './AddNewCheckGroup.vue'
import EditCheckGroup from './EditCheckGroup.vue'
import UpdateStatusCheckGroup from './UpdateStatusCheckGroup.vue'

export default {
  components: {
    StatusBlock,
    AddNewCheckGroup,
    EditCheckGroup,
    UpdateStatusCheckGroup,
  },
  setup() {
    const {
      isUpdateStatus,
      checkgroup_id,
      checkgroup_status_id_update,
      isEditCheckGroup,
      dataEdit,
      isAddNewCheckGroup,
      options,
      searchtext,
      statusList,
      checkgroup_status_id,
      columns,
      dataTableList,
      loading,
      fetchDataTable,
    } = useCheckGroup()

    return {
      isUpdateStatus,
      checkgroup_id,
      checkgroup_status_id_update,
      isEditCheckGroup,
      dataEdit,
      isAddNewCheckGroup,
      options,
      searchtext,
      statusList,
      checkgroup_status_id,
      columns,
      dataTableList,
      loading,
      fetchDataTable,
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
