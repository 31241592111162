<template>
  <div>
    <v-dialog
      v-model="isEditCheckGroup"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_examination_category') }}
        </v-card-title>
        <v-form
          ref="formEditCheckGroup"
          @submit.prevent="createCheckGroup"
        >
          <v-card-text>
            <v-text-field
              v-model="shop_name"
              outlined
              dense
              :label="$t('clinic_name')"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.checkgroup_name"
              outlined
              class="mt-3"
              dense
              :rules="[required]"
              :label="$t('examination_category')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-check-group',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkGroup from '@/api/examination/checkGroup'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditCheckGroup',
    event: 'update:is-edit-check-group',
  },
  props: {
    isEditCheckGroup: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditCheckGroup = ref(null)
    const loading = ref(false)
    const dataEditLocal = ref({})
    const shop_name = ref(localStorage.getItem('shop_name'))

    const { checkGroupUpdate } = checkGroup

    const createCheckGroup = () => {
      const isFormValid = formEditCheckGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkGroupUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-check-group', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, val => {
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      required,
      formEditCheckGroup,
      loading,
      dataEditLocal,
      shop_name,
      createCheckGroup,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
