import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import checkGroup from '@/api/examination/checkGroup'
import { i18n } from '@/plugins/i18n'

export default function useCheckGroup() {
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const checkgroup_status_id = ref(1)

  const columns = ref([
    {
      text: '#', align: 'start', sortable: false, value: 'checkgroup_id', width: 30,
    },
    {
      text: i18n.t('examination_category'), value: 'checkgroup_name', width: 400,
    },
    {
      text: i18n.t('status'), value: 'checkgroup_status_id', width: 200,
    },
    {
      text: i18n.t('option'), value: 'actions', align: 'center', width: 120,
    },
  ])
  const dataTableList = ref([])
  const options = ref({})
  const loading = ref(false)

  const isAddNewCheckGroup = ref(false)

  const isEditCheckGroup = ref(false)
  const dataEdit = ref({})

  const isUpdateStatus = ref(false)
  const checkgroup_id = ref('')
  const checkgroup_status_id_update = ref('')

  const { checkGroupList } = checkGroup

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    checkGroupList({
      searchtext: searchtext.value,
      checkgroup_status_id: checkgroup_status_id.value,
    }).then(res => {
      dataTableList.value = res
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, checkgroup_status_id], (newvalue, oldvalue) => {
    if (newvalue !== oldvalue) {
      fetchDataTable()
    }
  })

  return {
    isUpdateStatus,
    checkgroup_id,
    checkgroup_status_id_update,
    isEditCheckGroup,
    dataEdit,
    isAddNewCheckGroup,
    searchtext,
    statusList,
    checkgroup_status_id,
    columns,
    dataTableList,
    options,
    loading,
    fetchDataTable,
  }
}
