<template>
  <div>
    <v-dialog
      v-model="isAddNewCheckGroup"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_examination_category') }}
        </v-card-title>
        <v-form
          ref="formAddCheckGroup"
          @submit.prevent="createCheckGroup"
        >
          <v-card-text>
            <v-text-field
              v-model="shop_name"
              outlined
              dense
              :label="$t('clinic_name')"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="checkgroup_name"
              outlined
              class="mt-3"
              dense
              :rules="[required]"
              :label="$t('examination_category')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-check-group',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkGroup from '@/api/examination/checkGroup'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isAddNewCheckGroup',
    event: 'update:is-add-new-check-group',
  },
  props: {
    isAddNewCheckGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddCheckGroup = ref(null)
    const loading = ref(false)
    const checkgroup_name = ref('')
    const shop_name = ref(localStorage.getItem('shop_name'))

    const { checkGroupAdd } = checkGroup

    const createCheckGroup = () => {
      const isFormValid = formAddCheckGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkGroupAdd({
        checkgroup_name: checkgroup_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check-group', false)
        loading.value = false
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewCheckGroup, val => {
      if (val) {
        checkgroup_name.value = ''
      }
    })

    return {
      required,
      formAddCheckGroup,
      loading,
      checkgroup_name,
      shop_name,
      createCheckGroup,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
